import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import closeMenuIcon from './../../assets/images/close.svg'
import { styles } from './styles'
import CHRContentContainer from './../contentContainer'
import CHRButton from './../button'
import { EXTENSION_ID, FONTS, ROUTES } from '../../constants'
// import CHROutlinedButton from './../outlinedButton'
import { navigate, Link } from 'gatsby'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Auth from '@aws-amplify/auth'
import { sendExtensionMessage } from '../../../utils/extensionHelper'
import { useAppState } from '../../appState'
import CategoriesMenu from './CategoriesMenu'
import Banner from '../header/Banner'
// import birdAvatar from '../../assets/images/bird-avatar-plain.svg'
// import Divider from '@material-ui/core/Divider'
// import * as moment from 'moment'
// import { Typography } from '@material-ui/core'
import chirpyestTextLogo from '../../assets/images/chirpyest-text-logo.svg'
import chirpyestLogo from '../../assets/images/chirpyest-logo.svg'
import CHRAccordion from '../accordion'
interface CHRHeaderDialogProps {
  handleClose: Function
  openStatus: boolean
  openSignInModal: Function
  t: TFunction
}

const CHRHeaderDialog = ({
  handleClose,
  openStatus,
  openSignInModal,
  t,
}: CHRHeaderDialogProps) => {
  const classes = styles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const [isMenuOpen, setMenuStatus] = useState(false)
  const [appState, dispatch] = useAppState()
  const [isCopied, setIsCopied] = useState(false)
  const userNameId = `${appState.username}-${appState.userId}`
  const isLoggedIn = appState.userId > 0
  const homeOrMemberRoute = isLoggedIn ? ROUTES.member : ROUTES.home

  const isTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(
    navigator.userAgent
  )
  const handleCopyLink = () => {
    const joinLink = `${process.env.GATSBY_SITE_URL}/join/${appState.username}-${appState.userId}`
    navigator.clipboard.writeText(joinLink)
    setIsCopied(true)
  }

  const handleSignOut = async () => {
    try {
      handleClose()
      dispatch({ type: 'SIGN_OUT' })
      navigate(ROUTES.home)
      window.localStorage.removeItem('user_id')
      window.localStorage.removeItem('movedItems')
      window.localStorage.removeItem('ig_token')
      window.localStorage.removeItem('referralId')
      window.localStorage.removeItem('profile')
      sendExtensionMessage({ action: 'signOut' })
      // keep compatibility with older extension
      if (window.chrome?.runtime?.sendMessage) {
        window.chrome.runtime.sendMessage(
          EXTENSION_ID,
          { action: 'signOut' },
          (_response: any) => {}
        )
      }
      Auth.signOut()
    } catch (error) {
      return error
    }
  }

  const shoppingBoardLink = appState.username
    ? ROUTES.mainBoard.replace(':userName', appState.username)
    : ROUTES.settings

  return (
    <div>
      <Dialog
        className={isTablet ? classes.dialogTablet : ''}
        fullScreen={fullScreen}
        open={openStatus}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <>
          {!isTablet ? <Banner isLoggedIn={isLoggedIn} /> : null}

          <CHRContentContainer>
            <div className={classes.logoandClose}>
              <DialogTitle
                id="responsive-dialog-title1"
                className={
                  classes.dialogTitleSection +
                  ' ' +
                  (isTablet ? classes.dialogTitleSectionTablet : '')
                }
              >
                <div className="flex">
                  <button onClick={handleClose} className={classes.closeButton}>
                    <img
                      src={closeMenuIcon}
                      alt={`${t('imageAlts.close')} ${t('imageAlts.menu')}`}
                    />
                  </button>
                </div>
              </DialogTitle>

              {isTablet && (
                <div>
                  <Link
                    rel="preconnect"
                    to={isLoggedIn ? ROUTES.member : ROUTES.home}
                    className={`${classes.chirpyestLogoLink}`}
                  >
                    <img src={chirpyestLogo} alt="chipryest-logo-icon" />
                  </Link>
                </div>
              )}
              {!isTablet && (
                <div className={classes.chirpyestLogo}>
                  <Link
                    rel="preconnect"
                    to={isLoggedIn ? ROUTES.member : ROUTES.home}
                  >
                    <img
                      src={chirpyestTextLogo}
                      alt={t('shared.chirpyest')}
                      className={classes.chirpyestTextLogoMinimized}
                    />
                  </Link>
                </div>
              )}
            </div>

            <CategoriesMenu
              openStatus={isMenuOpen}
              handleClose={() => setMenuStatus(false)}
              closeMenu={handleClose}
            />
            {!isMenuOpen && (
              <DialogContent
                className={
                  classes.menuPadding +
                  ' ' +
                  (isTablet ? classes.navListTablet : '')
                }
              >
                <nav
                  className={classes.navList + ' ' + classes.mainNavList}
                  style={{ marginBottom: '33px', maxWidth: '375px' }}
                >
                  {!isLoggedIn && (
                    <DialogContentText
                      className={classes.listItem}
                      color="primary"
                    >
                      <Link
                        onClick={() => handleClose()}
                        to={ROUTES.howItWorks}
                      >
                        {t('shared.howItWorks')}
                      </Link>
                    </DialogContentText>
                  )}

                  <DialogContentText
                    className={classes.listItem}
                    color="primary"
                  >
                    <Link onClick={() => handleClose()} to={ROUTES.brands}>
                      {t('shared.brands')}
                    </Link>
                  </DialogContentText>

                  <DialogContentText
                    className={classes.listItem + ' ' + classes.shopPadding}
                    color="primary"
                  >
                    <CHRAccordion
                      // variant="p"
                      title={t('shared.shop')}
                      customStyle={{ backgroundColor: 'initial' }}
                    >
                      <DialogContent
                        className={
                          classes.menuPadding + ' ' + classes.subMenuPadding
                        }
                      >
                        <nav
                          className={classes.navList + ' ' + classes.subNavList}
                        >
                          <DialogContentText
                            className={classes.listItem}
                            color="primary"
                          >
                            <Link
                              onClick={() => handleClose()}
                              to={ROUTES.fashionCategory}
                            >
                              {t('shared.fashion')}
                            </Link>
                          </DialogContentText>
                          <DialogContentText
                            className={classes.listItem}
                            color="primary"
                          >
                            <Link
                              onClick={() => handleClose()}
                              to={ROUTES.homeCategory}
                            >
                              {t('shared.home')}
                            </Link>
                          </DialogContentText>
                          <DialogContentText
                            className={classes.listItem}
                            color="primary"
                          >
                            <Link
                              onClick={() => handleClose()}
                              to={ROUTES.beautyCategory}
                            >
                              {t('shared.beauty')}
                            </Link>
                          </DialogContentText>
                          <DialogContentText
                            className={classes.listItem}
                            color="primary"
                          >
                            <Link
                              onClick={() => handleClose()}
                              to={ROUTES.lifestyleCategory}
                            >
                              {t('shared.lifestyle')}
                            </Link>
                          </DialogContentText>
                        </nav>
                      </DialogContent>
                    </CHRAccordion>
                  </DialogContentText>

                  {isLoggedIn && (
                    <>
                      <DialogContentText
                        className={classes.listItem}
                        color="primary"
                      >
                        {t('shared.share')}
                      </DialogContentText>
                      <DialogContentText
                        className={
                          classes.listItem + ' ' + classes.subNavListItem
                        }
                        color="primary"
                      >
                        <Link
                          onClick={() => handleClose()}
                          to={shoppingBoardLink}
                        >
                          {t('home.shoppingBoard')}
                        </Link>
                      </DialogContentText>
                      <DialogContentText
                        className={
                          classes.listItem + ' ' + classes.subNavListItem
                        }
                        color="primary"
                      >
                        <Link
                          onClick={() => handleClose()}
                          to={ROUTES.createShareLink}
                        >
                          {t('shared.shareLink')}
                        </Link>
                      </DialogContentText>
                      <DialogContentText
                        className={
                          classes.listItem + ' ' + classes.subNavListItem
                        }
                        color="primary"
                      >
                        <Link
                          onClick={() => handleClose()}
                          to={ROUTES.theChirpyest}
                        >
                          {t('shared.theChirpyest')}
                        </Link>
                      </DialogContentText>

                      <DialogContentText
                        className={classes.listItem}
                        color="primary"
                      >
                        {t('shared.earn')}
                      </DialogContentText>
                      <DialogContentText
                        className={
                          classes.listItem + ' ' + classes.subNavListItem
                        }
                        color="primary"
                      >
                        <Link
                          onClick={() => handleClose()}
                          to={ROUTES.yourAccount}
                        >
                          {t('header.yourAccount')}
                        </Link>
                      </DialogContentText>
                      <DialogContentText
                        className={
                          classes.listItem + ' ' + classes.subNavListItem
                        }
                        color="primary"
                      >
                        {appState?.userProfile?.isReferralBonus
                          ? t('shared.referFriendAndEarn')
                          : t('shared.referFriendAndEarn').replace(
                              '- earn $10!',
                              ''
                            )}
                        {'  '}
                        <span
                          className={classes.joinLinkSpan}
                          onClick={handleCopyLink}
                        >
                          {isCopied ? 'copied' : 'copy link'}
                        </span>
                      </DialogContentText>
                    </>
                  )}

                  {!isLoggedIn && (
                    <DialogContentText
                      className={classes.listItem}
                      color="primary"
                    >
                      <Link
                        onClick={() => handleClose()}
                        to={ROUTES.theChirpyest}
                      >
                        {t('shared.theChirpyest')}
                      </Link>
                    </DialogContentText>
                  )}

                  <DialogContentText
                    className={classes.listItem}
                    color="primary"
                  >
                    <a href="https://blog.chirpyest.com/" target="_blank">
                      {t('shared.blog')}
                    </a>
                  </DialogContentText>

                  {isLoggedIn && (
                    <DialogContentText
                      className={classes.listItem}
                      color="primary"
                    >
                      <Link
                        onClick={() => handleClose()}
                        to={ROUTES.howItWorks}
                      >
                        {t('shared.howItWorks')}
                      </Link>
                    </DialogContentText>
                  )}

                  <DialogContentText
                    className={classes.listItem}
                    color="primary"
                  >
                    <Link
                      onClick={() => handleClose()}
                      to={ROUTES.browserExtension}
                    >
                      {t('company.browserExtension.title')}
                    </Link>
                  </DialogContentText>

                  {!isLoggedIn && (
                    <>
                      <div className={classes.loginAndSignUp}>
                        <CHRButton
                          onClick={() => {
                            openSignInModal()
                          }}
                          label={t('header.signIn')}
                        />
                        <CHRButton
                          onClick={() => {
                            handleClose()
                            navigate(ROUTES.join)
                          }}
                          label={t('shared.join')}
                        />
                      </div>
                    </>
                  )}
                  {isLoggedIn && (
                    <>
                      <div className={classes.loggedIn}>
                        <CHRButton
                          onClick={() => {
                            handleSignOut()
                          }}
                          label={t('header.signOut')}
                        />
                      </div>
                    </>
                  )}
                  {/* {isLoggedIn && (
                    <DialogContentText
                      className={classes.listItem}
                      color="primary"
                    >
                      <a href="https://blog.chirpyest.com/" target="_blank">
                        {t('shared.blog')}
                      </a>
                    </DialogContentText>
                  )} */}

                  {/* {isLoggedIn && appState.username && (
                    <DialogContentText
                      className={classes.listItem}
                      color="primary"
                    >
                      <p>
                        refer a friend, get $10! use join link <span> </span>{' '}
                        <br />
                        <span
                          className={classes.joinLinkSpan}
                          onClick={handleCopyLink}
                        >
                          chirpyest.com/join/{userNameId}
                        </span>
                      </p>
                      {isCopied && (
                        <Typography
                          variant="caption"
                          className={classes.copiedCaption}
                        >
                          code copied!
                        </Typography>
                      )}
                    </DialogContentText>
                  )} */}
                </nav>
                {/* {isLoggedIn && (
                  <div
                    className={classes.profileWrapper}
                    onClick={() => navigate(shoppingBoardLink)}
                  >
                    <span className={classes.avatarContainer}>
                      {appState.userProfile.imageUrl ? (
                        <img
                          src={appState.userProfile.imageUrl}
                          className={classes.userAvatar}
                        />
                      ) : (
                        <img
                          src={birdAvatar}
                          alt={t('shared.chirpyest')}
                          className={classes.birdAvatar}
                        />
                      )}
                    </span>
                    <DialogContentText
                      className={classes.username}
                      color="primary"
                    >
                      {appState.username || appState.userProfile.fullName}
                    </DialogContentText>
                  </div>
                )} */}
              </DialogContent>
            )}
          </CHRContentContainer>
        </>
        {/* {isLoggedIn && (
          <div className={classes.profileNav}>
            <DialogContentText className={classes.cashBack} color="primary">
              {t('header.cashBackPending')}: $
              {appState.userProfile.financialData?.pending.toFixed(2)}
            </DialogContentText>
            <DialogContentText className={classes.cashBack} color="primary">
              {t('header.cashBackPaid')}({moment().year()}): $
              {appState.userProfile.financialData?.receivableMilestone.toFixed(
                2
              )}
            </DialogContentText>
            <Divider
              style={{
                margin: '15px 0 30px 0',
                background: 'black',
              }}
            />
            <nav className={classes.navList}>
              <DialogContentText className={classes.listItem} color="primary">
                <Link onClick={() => handleClose()} to={ROUTES.createShareLink}>
                  {t('createLink.createShareLink')}
                </Link>
              </DialogContentText>
              <DialogContentText className={classes.listItem} color="primary">
                <Link onClick={() => handleClose()} to={shoppingBoardLink}>
                  {t('header.yourShoppingBoard')}
                </Link>
              </DialogContentText>
              <DialogContentText className={classes.listItem} color="primary">
                <Link onClick={() => handleClose()} to={ROUTES.cashback}>
                  {t('header.yourAccount')}
                </Link>
              </DialogContentText>
              <DialogContentText className={classes.listItem} color="primary">
                <a onClick={() => handleSignOut()}>{t('header.signOut')}</a>
              </DialogContentText>
              
            </nav>
          </div>
        )} */}
      </Dialog>
    </div>
  )
}

export default withTranslation()(CHRHeaderDialog)
